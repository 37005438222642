import { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import "../styles/Home.css";


import mainIcon from '../img/main_icon_new.png';


import icon01 from '../img/icon_01.png';
import icon02 from '../img/icon_02.png';
import icon03 from '../img/icon_03.png';
import icon04 from '../img/icon_04.png';
import icon05 from '../img/icon_05.png';
import icon06 from '../img/icon_06.png';
import icon07 from '../img/icon_07.png';

import plus from '../img/plus.png';

import lawyer01 from '../img/lawyer_01.jpg';
import lawyer02 from '../img/lawyer_02.jpg';
import lawyer03 from '../img/lawyer_03.jpg';
import lawyer04 from '../img/lawyer_04.jpg';
import lawyer05 from '../img/lawyer_05.jpg';




function Home() {

    const [activeIndex, setActiveIndex] = useState(0);

    /* 세부페이지 탭이동 */

    const [showTags, setShowTags] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setShowTags(true);
      }, 800);
    }, []);

    const [showTags2, setShowTags2] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setShowTags2(true);
      }, 1800);
    }, []);


    const [showTags3, setShowTags3] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setShowTags3(true);
      }, 2400);
    }, []);

    return (
        <>
        <article className='main'>
            <div className="txt_box">
                <div className="main_icon">
                    <img src={mainIcon} alt="아이콘" className={showTags ? 'show1' : ''} />
                </div>
                {/* <h2>
                    법무법인 정도의 변호사들은 변호사 경력 12년 ~ 20년 경력의 <br/>
                    베테랑 변호사들이며, 전원 사법연수원 출신으로 구성되어 있습니다. 
                </h2>
                <p>
                    각자 다른 이력을 가진 법무법인 등에서 일하면서 고객의 이익과 구성원의 이익을 등한시하고 자신의 이익만을 추구하는 대표,
                    <br />
                    또는 다른 변호사들의 모습을 보면서 올바른 변호사로서의 모습을 갈망하여 한 뜻으로 뭉치게 되었습니다.
                </p> */}
                <h2  className={showTags2 ? 'show2' : ''}>법무법인 | 정도(正道)</h2>
                <p  className={showTags3 ? 'show3' : ''}>
                    법무법인 정도는 <br/>  다양한 분야에서 폭 넓은 경험과 전문지식을 바탕으로 <br/> 최고의 법률 서비스를 제공합니다. <br/>
                </p>
                {/* <p>
                 의뢰인의 말을 소중히 여기고 작은 것이라도 함께 하는 든든한 동반자로서의 역할에 최선을 다하겠습니다.
                    <br />
                </p> */}
                {/* <h2>법무법인 | 정도(正道)</h2>
                <p className='title_name3'>
                    법무법인 정도(正道)는<br />바른 길을 걸어 나가겠습니다.
                </p> */}
            </div>
        </article>

           

            <article className="business">
                <div className="inner">
                    <div className="con_tit">
                        <h2>업무분야</h2>
                        <p>법무법인 정도의 <br />업무분야를 소개합니다.</p>
                    </div>

                    <ul className="business_con_wrap">
                        <li>
                            <div>
                                <Link to="/works#civil_affairs">
                                    <div className="b_img_wrap">
                                        <img src={icon01} className="b_icon" alt="일반민사" />
                                    </div>
                                    <h3>일반민사</h3>

                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#real_estate">
                                    <div className="b_img_wrap">
                                        <img src={icon02} className="b_icon" alt="부동산" />
                                    </div>
                                    <h3>부동산</h3>

                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#compensation_for_damages">
                                    <div className="b_img_wrap">
                                        <img src={icon03} className="b_icon" alt="손해배상" />
                                    </div>
                                    <h3>손해배상</h3>
                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#criminal_case">
                                    <div className="b_img_wrap">
                                        <img src={icon04} className="b_icon" alt="형사" />
                                    </div>
                                    <h3>형사</h3>
                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#labor">
                                    <div className="b_img_wrap">
                                        <img src={icon05} className="b_icon" alt="노동" />
                                    </div>
                                    <h3>노동</h3>
                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#family_litigation">
                                    <div className="b_img_wrap">
                                        <img src={icon06} className="b_icon" alt="가사" />
                                    </div>
                                    <h3>가사</h3>
                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Link to="/works#fair_trade">
                                    <div className="b_img_wrap">
                                        <img src={icon07} className="b_icon" alt="공정거래" />
                                    </div>
                                    <h3>공정거래</h3>
                                    <div className='more'>
                                        <img src={plus} alt="더보기" />
                                    </div>
                                </Link>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </article>
            <article className="lawyer">
                <div className="inner">
                    <div className="con_tit head_office">
                        {/* <h2>주사무소</h2> */}
                        <h2>법무법인 정도의 <br/> 변호사를 <br/>소개합니다.</h2>
                    </div>
        
                    <ul className="represent">
                        <li>
                            <Link to={'members#lawyer-lee_han_bon'}>
                                <div className="img_box">
                                    <img src={lawyer03} alt="이한본 변호사"></img>
                                </div>
                                <h3>변호사 이한본</h3>
                            </Link>
                        </li>
                        <li>
                            <Link to="/members#lawyer-yang_chang_yeong">
                                <div className="img_box">
                                    <img src={lawyer01} alt="양창영 변호사"></img>
                                </div>
                                <h3>변호사 양창영</h3>
                            </Link>
                        </li>
                        <li>
                            <Link to="/members#lawyer-kim_byeong_jae">
                                <div className="img_box">
                                    <img src={lawyer04} alt="김병재 변호사"></img>
                                </div>
                                <h3>대표 변호사 김병재</h3>
                            </Link>
                        </li>
                        <li>
                            <Link to="/members#lawyer-seol_chang_il">
                                <div className="img_box">
                                    <img src={lawyer02} alt="설창일 변호사"></img>
                                </div>
                                <h3>변호사 설창일</h3>
                            </Link>
                        </li>
                        <li>
                            <Link to="/members#lawyer-kim_ji_mi">
                                <div className="img_box">
                                    <img src={lawyer05} alt="김지미 변호사"></img>
                                </div>
                                <h3>변호사 김지미</h3>
                            </Link>
                        </li>
                    </ul>

                    <div className="con_tit branch_office">
                        <h2>분사무소</h2>
                        <p>
                            {/*
                            <span>
                                <b>안양 분사무소</b> <br/>
                                <strong>대표</strong> 변호사 김용기
                            </span>
                            */}
                            <span>
                                <b>천안 분사무소</b> <br/>
                                변호사 임종석
                            </span>
                            <span>
                                <b>평택 분사무소</b> <br/>
                                변호사 설창일
                            </span>
                            <span>
                                <b>서초 분사무소</b> <br/>
                                <strong>대표</strong> 변호사 김용기
                                &nbsp;
                                &nbsp;
                                변호사 이명춘
                                &nbsp;
                                &nbsp;
                                변호사 최건섭
                            </span>
                        </p>
                    </div>
                </div>
            </article>       
            
            <article className="location">
                <div className="inner">
                    <div className="con_tit">
                        <h2>법무법인 정도 오시는 길</h2>
                    </div>

                    <div className="content_wrap">
                        <ul className="list_box">
                            <li className={`tap_li ${activeIndex === 0 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.382081338524!2d126.86028247636058!3d37.52248962665182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9dd478dda721%3A0x7f7e0d355e6809c3!2z7ISc7Jq47Yq567OE7IucIOyWkeyynOq1rCDsi6Dsm5TroZwgMzc3!5e0!3m2!1sko!2skr!4v1691474841285!5m2!1sko!2skr" title="주사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도</dt>
                                        <dd>
                                            서울 양천구 신월로 377, 2층(신정동, 진성빌딩)
                                            <br />
                                            우)08023
                                        </dd>
                                    </dl>
                                    <p>목동역 7번 출구 도보 8분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            02-2692-9005 / 02-2603-3309
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            02-2692-8007 / 02-2603-3256
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            

                            {/*
                            <li className={`tap_li ${activeIndex === 1 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.683373540874!2d126.96055547635615!3d37.397319533812365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5e229389be11%3A0x7435460a3e1a673d!2z6rK96riw64-EIOyViOyWkeyLnCDrj5nslYjqtawg67aA66a866GcIDE1Ng!5e0!3m2!1sko!2skr!4v1691396430815!5m2!1sko!2skr" title="안양사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 안양분사무소</dt>
                                        <dd>
                                            안양시 동안구 부림로 156, 203호 (안양법조타운)
                                            <br />
                                            우)14055
                                        </dd>
                                    </dl>
                                    <p>평촌역 3번 출구 도보 5분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            031-421-1777
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            031-421-1776
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            */}

                            <li className={`tap_li ${activeIndex === 2 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3195.341231566023!2d127.15615947633516!3d36.786368168478354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ad7d7e5509723%3A0xda80d66ef0176969!2z7Lap7LKt64Ko64-EIOyynOyViOyLnCDrj5nrgqjqtawg7LKt7IiYN-uhnCA0NQ!5e0!3m2!1sko!2skr!4v1691396448695!5m2!1sko!2skr" title="천안사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 천안분사무소</dt>
                                        <dd>
                                            충남 천안시 동남구 청수7로 45, 5층 (신협빌딩)
                                            <br />
                                            우)31197
                                        </dd>
                                    </dl>
                                    <p>청룡동 행정복지센터 도보 3분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            041-568-6700
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            041-568-6702
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <li className={`tap_li ${activeIndex === 3 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1593.007461788427!2d127.09404233888428!3d37.009440334889696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b3a45dc66a805%3A0x990fe44374e9385c!2z6rK96riw64-EIO2Pie2DneyLnCDtj4nrgqjroZwgMTA0NQ!5e0!3m2!1sko!2skr!4v1697076941712!5m2!1sko!2skr" title="평택사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 평택분사무소</dt>
                                        <dd>
                                            경기도 평택시 평남로 1045, 302호(동삭동, 손문빌딩)
                                            <br />
                                            우)17849
                                        </dd>
                                    </dl>
                                    <p>수원지방검찰청 평택지청 도보 2분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            031-692-5201
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            031-692-5202 
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <br />
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                            <li className={`tap_li ${activeIndex === 4 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.583819151288!2d127.0050051889304!3d37.49414635623934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca16dcfb5cca3%3A0x19af45a56656f23f!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDrspXsm5DroZwgMTU!5e0!3m2!1sko!2skr!4v1691396404969!5m2!1sko!2skr" title="서초사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 서초분사무소</dt>
                                        <dd>
                                            서울 서초구 법원로 15, 404호 (서초동, 정곡빌딩)
                                            <br />
                                            우)14055
                                        </dd>
                                    </dl>
                                    <p>서초역 8번 출구 도보 6분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            02-595-7088
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            02-595-7089
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>

                        </ul>
                        <div className='tab_component'>
                            <ul className='tab_items'>
                                <li className={`tap_li ${activeIndex === 0 ? 'active' : ''}`} onClick={() => setActiveIndex(0)}><Link to="#">주사무소</Link></li>
                                {/*<li className={`tap_li ${activeIndex === 1 ? 'active' : ''}`} onClick={() => setActiveIndex(1)}><Link to="#">안양분사무소</Link></li>*/}
                                <li className={`tap_li ${activeIndex === 2 ? 'active' : ''}`} onClick={() => setActiveIndex(2)}><Link to="#">천안분사무소</Link></li>
                                <li className={`tap_li ${activeIndex === 3 ? 'active' : ''}`} onClick={() => setActiveIndex(3)}><Link to="#">평택분사무소</Link></li>
                                <li className={`tap_li ${activeIndex === 4 ? 'active' : ''}`} onClick={() => setActiveIndex(4)}><Link to="#">서초분사무소</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </article>


        </>

    )
}

export default Home