import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import "../styles/Location.css";

function Location() {

    const [activeIndex, setActiveIndex] = useState(0);

    
    return (
        <>
            <article className="s_location">
                <div className="inner">

                    
                    <p className='breadCrumb'>
                        HOME &gt; 정도소개 &gt; <span>오시는 길</span>
                    </p>
                    <div className="title">
                        <h2>오시는 길</h2>
                    </div>

                    <div className="s_location_tab">
                        <ul className="s_location_tab_items">
                            <li className={`tap_li ${activeIndex === 0 ? 'active' : ''}`} onClick={() => setActiveIndex(0)}><Link to="#">주사무소</Link></li>
                            {/*
                            <li className={`tap_li ${activeIndex === 1 ? 'active' : ''}`} onClick={() => setActiveIndex(1)}><Link to="#">안양분사무소</Link></li>
                            */}
                            <li className={`tap_li ${activeIndex === 2 ? 'active' : ''}`} onClick={() => setActiveIndex(2)}><Link to="#">천안분사무소</Link></li>
                            <li className={`tap_li ${activeIndex === 3 ? 'active' : ''}`} onClick={() => setActiveIndex(3)}><Link to="#">평택분사무소</Link></li>
                            <li className={`tap_li ${activeIndex === 4 ? 'active' : ''}`} onClick={() => setActiveIndex(4)}><Link to="#">서초분사무소</Link></li>
                        </ul>
                    </div>


                    <div className="content_wrap">
                        <ul className="list_box">
                            <li className={`tap_li ${activeIndex === 0 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.382081338524!2d126.86028247636058!3d37.52248962665182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9dd478dda721%3A0x7f7e0d355e6809c3!2z7ISc7Jq47Yq567OE7IucIOyWkeyynOq1rCDsi6Dsm5TroZwgMzc3!5e0!3m2!1sko!2skr!4v1691474841285!5m2!1sko!2skr" title="주사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도</dt>
                                        <dd>
                                            서울 양천구 신월로 377, 2층(신정동, 진성빌딩)
                                            <br />
                                            우)08023
                                        </dd>
                                    </dl>
                                    <p>목동역 7번 출구 도보 8분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            02-2692-9005 / 02-2603-3309
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            02-2692-8007 / 02-2603-3256
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            

                            {/*
                            <li className={`tap_li ${activeIndex === 1 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.683373540874!2d126.96055547635615!3d37.397319533812365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5e229389be11%3A0x7435460a3e1a673d!2z6rK96riw64-EIOyViOyWkeyLnCDrj5nslYjqtawg67aA66a866GcIDE1Ng!5e0!3m2!1sko!2skr!4v1691396430815!5m2!1sko!2skr" title="안양사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 안양분사무소</dt>
                                        <dd>
                                            안양시 동안구 부림로 156, 203호 (안양법조타운)
                                            <br />
                                            우)14055
                                        </dd>
                                    </dl>
                                    <p>평촌역 3번 출구 도보 5분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            031-421-1777
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            031-421-1776
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            */}

                            <li className={`tap_li ${activeIndex === 2 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3195.341231566023!2d127.15615947633516!3d36.786368168478354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ad7d7e5509723%3A0xda80d66ef0176969!2z7Lap7LKt64Ko64-EIOyynOyViOyLnCDrj5nrgqjqtawg7LKt7IiYN-uhnCA0NQ!5e0!3m2!1sko!2skr!4v1691396448695!5m2!1sko!2skr" title="천안사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 천안분사무소</dt>
                                        <dd>
                                            충남 천안시 동남구 청수7로 45, 5층 (신협빌딩)
                                            <br />
                                            우)31197
                                        </dd>
                                    </dl>
                                    <p>청룡동 행정복지센터 도보 3분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            041-568-6700
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            041-568-6702
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>


                            <li className={`tap_li ${activeIndex === 3 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1593.007461788427!2d127.09404233888428!3d37.009440334889696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b3a45dc66a805%3A0x990fe44374e9385c!2z6rK96riw64-EIO2Pie2DneyLnCDtj4nrgqjroZwgMTA0NQ!5e0!3m2!1sko!2skr!4v1697076941712!5m2!1sko!2skr" title="인천사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 평택분사무소</dt>
                                        <dd>
                                            경기도 평택시 평남로 1045, 302호(동삭동, 손문빌딩)
                                            <br />
                                            우)17849
                                        </dd>
                                    </dl>
                                    <p>수원지방검찰청 평택지청 도보 2분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            031-692-5201
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            031-692-5202 
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <br />
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className={`tap_li ${activeIndex === 4 ? 'active' : ''}`}>
                                <div className="map_box">
                                    <div className="map-wrap">
                                        <div className="map">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.583819151288!2d127.0050051889304!3d37.49414635623934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca16dcfb5cca3%3A0x19af45a56656f23f!2z7ISc7Jq47Yq567OE7IucIOyEnOy0iOq1rCDrspXsm5DroZwgMTU!5e0!3m2!1sko!2skr!4v1691396404969!5m2!1sko!2skr" title="서초사무소지도"></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="info_box">
                                    <dl>
                                        <dt>법무법인 정도 서초분사무소</dt>
                                        <dd>
                                            서울 서초구 법원로 15, 404호 (서초동, 정곡빌딩)
                                            <br />
                                            우)14055
                                        </dd>
                                    </dl>
                                    <p>서초역 8번 출구 도보 6분</p>
                                    <ul>
                                        <li>
                                            <span>전화번호</span>
                                            02-595-7088
                                        </li>
                                        <li>
                                            <span>팩스번호</span>
                                            02-595-7089
                                        </li>
                                        <li>
                                            <span>영업시간</span>
                                            평일 09:00 - 18:00
                                            <span className="add">* 공휴일도 예약 시 상담 등 업무가능</span>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>



                    </div>
                </div>
            </article>
        </>
    )
}

export default Location